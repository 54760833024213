/* eslint-disable react/jsx-props-no-spreading */
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { StructuredText } from 'react-datocms';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import { Navigator } from '../components/LanguageHelpers/Navigator';
import { PageWrapper } from '../components/layout/PageWrapper';

const Wrapper = styled.section`
  display: grid;
  justify-content: center;
  padding: var(--padding);
  max-width: var(--layout-desktop-container);
  gap: var(--gap-4);
  width: 100%;
  margin: auto;
`;

const commonBlockStyles = css`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: var(--gap-4);

  @media screen and (max-width: 1120px) {
    grid-template-columns: 1fr;
    gap: var(--gap-2);
  }
`;

const FirstBlock = styled.div`
  ${commonBlockStyles};
`;

const SecondBlock = styled.div`
  ${commonBlockStyles};

  @media screen and (max-width: 1120px) {
    & div:last-of-type {
      grid-row: 1;
    }
  }
`;

const StructuredBody = styled.div`
  & p {
    margin-bottom: 1.5em;
    line-height: 1.75;
    color: var(--blue-1);
  }
`;

const Title = styled.h1`
  color: var(--gold-2);
  font-size: var(--font-size-6);
  margin-bottom: var(--gap-4);
  line-height: 1.15;
  margin-top: var(--gap-6);

  @media screen and (max-width: 1120px) {
    font-size: var(--font-size-5);
  }
`;

const BackToNewsLink = styled(Navigator)`
  color: var(--gold-2);
  font-weight: 600;
  font-size: var(--font-size-micro-1);
  margin-bottom: var(--gap-6);

  &:hover {
    text-decoration: underline;
  }
`;

const Link = styled(Navigator)`
  text-decoration: underline;
  color: var(--gold-2);
  transition: color 200ms;
`;

const Article = ({
                   data: {
                     datoCmsNews: {
                       mainImage: { mainImageData },
                       title,
                       firstParagraph,
                       secondParagraph,
                       otherImage: { otherImageData },
                     },
                     datoCmsUpcomingPage: { id },
                   }
                 }) => {
  const intl = useIntl();

  return (
    <Wrapper>
      <FirstBlock>
        <GatsbyImage image={mainImageData} alt={title} objectFit="contain" />
        <StructuredBody>
          <BackToNewsLink recordId={id}>
            {`←
            ${intl.formatMessage({ id: 'newsBack' })}`}
          </BackToNewsLink>
          <Title>{title}</Title>
          <StructuredText
            data={firstParagraph}
            renderLinkToRecord={({
                                     record: { id: recordId },
                                     children,
                                     transformedMeta,
                                   }) => (
                                     <Link {...transformedMeta} recordId={recordId}>
                                       {children}
                                     </Link>
              )}
          />
        </StructuredBody>
      </FirstBlock>
      <SecondBlock>
        <StructuredBody>
          <StructuredText
            data={secondParagraph}
            renderLinkToRecord={({
                                     record: { id: recordId },
                                     children,
                                     transformedMeta,
                                   }) => (
                                     <Link {...transformedMeta} recordId={recordId}>
                                       {children}
                                     </Link>
              )}
          />
        </StructuredBody>
        <GatsbyImage image={otherImageData} alt={title} objectFit="contain" />
      </SecondBlock>
    </Wrapper>
  );
};

const ArticlePage = ({
  data,
  pageContext,
}) => {
  const {
    datoCmsNews: {
      seo
    }
  } = data;

  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.image?.seoImageUrl}
    >
      <Article data={data} />
    </PageWrapper>
  );
};

export default ArticlePage;

export const query = graphql`
  query NewsQuery($id: String!, $locale: String!) {
    datoCmsNews(originalId: { eq: $id }, locale: { eq: $locale }) {
      seo {
        seoTitle: title
        seoDescription: description
        image {
          seoImageUrl: url
        }
      }
      title
      mainImage {
        mainImageData: gatsbyImageData
      }
      firstParagraph {
        value
        links {
          ... on DatoCmsProdotti {
            id: originalId
          }
          ... on DatoCmsHeroProduct {
            id: originalId
          }
          ... on DatoCmsCategory {
            id: originalId
          }
        }
      }
      secondParagraph {
        value
        links {
          ... on DatoCmsProdotti {
            id: originalId
          }
          ... on DatoCmsHeroProduct {
            id: originalId
          }
          ... on DatoCmsCategory {
            id: originalId
          }
        }
      }
      otherImage {
        otherImageData: gatsbyImageData
      }
    }
    datoCmsUpcomingPage {
      id: originalId
    }
  }
`;
